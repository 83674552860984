@import 'src/variables';
.contacts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2vw;
  ul {
    list-style-type: none;
    padding-inline: 0;
    margin-block: 0;
  }
  &__title {
    color: $pink;
    font-family: Albertino;
  }
  &__map {
    margin: 4%;
    flex: 1;
    min-width: 50vw;
    min-height: 40vh;
    position: relative;
  }
  &__info {
    max-width: 650px;
    min-width: 250px;
    margin: 4%;
    font-size: clamp(14px, 1.9vw, 30px);
    color: $yellow;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
