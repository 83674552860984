.background {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  &__right {
    opacity: 15%;
    height: 100%;
    width: 50%;
    right: 0;
    position: absolute;
  }
  &__left {
    opacity: 15%;
    height: 100%;
    width: 50%;
    left: 0;
    position: absolute;
    transform: scaleX(-1);
  }
  &__top{
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    transform: scaleY(-1);
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
  }
  &__image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    &_top {
      background-image: url('../../assets/img/bg_middle.png');
      position: absolute;
      background-position-x: right;
      background-position-y: top;
      background-size: 60%;
    }
    &_bottom {
      background-image: url('../../assets/img/bg_corner.png');
      position: absolute;
      background-position-x: right;
      background-position-y: bottom;
      background-size: 70%;
    }
  }
}
