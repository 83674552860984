@import '../../variables';

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  flex: 0 0 10%;
  z-index: 1;
  height: unset !important;
  font-size: 2.1vmin;
  & > span {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 4px;
    cursor: unset;
  }
  &__links {
    display: inline-block;
    width: 2vw;
    min-width: 30px;
    min-height: 30px;
    height: 2vw;
    background-size: contain;
    background-repeat: no-repeat;
    filter: grayscale(70%);
    &:hover {
      filter: grayscale(0);
    }
    &_icon_phone {
      background-image: url('../../assets/img/Green_Phone_Font-Awesome.svg');
    }
    &_icon_fb{
      background-image: url('../../assets/img/f_logo_RGB-Blue_58.png');
    }
    &_icon_gm {
      background-image: url('../../assets/img/Google_Maps_icon_(2020).svg');
    }
    &_icon_email {
      background-image: url('../../assets/img/Circle-icons-email.svg');
    }
  }
}
