$pink: #f14298;
$blue: #3eb5e8;
$green: #8ec74a;
$violet: #7b3671;
$yellow: #fff212;
$orange: #f58634;
$black: #000000;
$white: #FFFFFF;

:export {
  pink: $pink;
  blue: $blue;
  green: $green;
  violet: $violet;
  yellow: $yellow;
  orange: $orange;
  black: $black;
  white: $white;
}

@mixin color_tacos {
  color: $pink;
}
@mixin color_quesadilla {
  color: $green;
}
@mixin color_burrito {
  color: $blue;
}