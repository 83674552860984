@import "variables";
@font-face {
  font-family: 'EngraversGothic';
  font-weight: bold; src: url('/assets/fonts/tt0586m_.ttf');
}
@font-face { font-family: Albertino; font-weight: bold; src: url('/assets/fonts/albertino.ttf');}
body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  background-color: $black;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
