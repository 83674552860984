@import '../../../variables';
.menu {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 10vh;
    margin-bottom: 2vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4vw;
  }
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 4vw;
    &__title {
      width: 100%;
      text-align: center;
      font-size: 1.5em;
      color: $pink;
      font-family: 'EngraversGothic';
      pointer-events: bounding-box;
      cursor: pointer;
    }
    &__items {
      margin-top: 2vh;
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 2vh;
      column-gap: 2vw;
      & > * {
        /*font-size: 2.8vmin;*/
        display: flex;
        flex-direction: column;
        flex: 1 1 40%;
        pointer-events: none;
        min-width: 40vmin;
      }
      &_type {
        &_tbq {
          flex-direction: column;
          flex: 3;
        }
      }
    }



    &__item {
      &__water-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 1vw;
      }
      &__tqb-price {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 2vw;
        :nth-child(1) {
          @include color_tacos;
        }
        :nth-child(2) {
          @include color_quesadilla;
        }
        :nth-child(3) {
          @include color_burrito;
        }
      }
      &__icons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        & > img {
         max-width: 3ex;
        }
      }
      &__text {
        & * > img {
          max-width: 3ex;
        }
        align-self: flex-end;
        ul {
          list-style-type: none;
        }
      }

      &__title,
      &__price {
        font-family: 'Albertino';
        color: $orange;
        font-size: clamp(0.9em,2vw,2.6vh);
        font-size-adjust: 5;
        .zl {
          font-size: 0.8em;
        }
      }
      &__price {
        flex: 1;
        text-align: end;
        vertical-align: bottom;
        align-self: end;
      }
      &__title {
        padding-left: 2vw;
      }
      &__description {
        color: $yellow;
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        font-size: clamp(0.85em,2vw,2.6vh);
      }
      &__header {
        display: flex;
        justify-content: space-between;
        /*font-size: 2.4vmin;*/
      }
    }
  }
}
.tomatillo {
  color: $green;
}
.jalapeno {
  color: $orange;
}
.tortille,
.tortillami,
.poblano,
.gallo,
.mole,
.achiote,
.adobo,
.chorizo,
.totopos,
.pasilla,
.chipotle,
.puya,
.carolina_reaper,
.habanero {
  color: $pink;
}

.menu_icon_ml {
  /*font-size: 1.5vmin;*/
  font-family: monospace;
  text-align: center;
  color: #f58634;
}
