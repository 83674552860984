@import 'src/variables';
.gallery {
  height: 78% !important;
  &__left-right-button{
    font-family: monospace;
    font-size: 8vh;
    color: white;
    padding: 0 5vw;
  }
  &__carousel {
    background-color: unset;
  }
}
