@import "../../variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;
  &__logo {
    height: 10vmax;
  }
  &__menu_item {
    font-size: 4vmin;
    color: $pink;
    font-family: "Albertino";
    pointer-events: bounding-box;
    cursor: pointer;
  }
}
