@import 'src/variables';
.menu__tbq {
  flex-direction: row;
  align-items: baseline;
  display: flex;
  &__description {
    display: flex;
    flex: 1;
    gap: 1vw;
    padding-bottom: 2vmin;
    text-align: right;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 2%;
    font-size: clamp(0.8em,2vw,2.5vh);
    &__element {
      display: flex;
      flex-direction: column;
      color: #f14298;
      justify-content: right;
      align-self: end;
    }
    &__element:nth-child(1) {
      @include color_tacos;
    }

    &__element:nth-child(2) {
      @include color_quesadilla;
    }

    &__element:nth-child(3) {
      @include color_burrito;
    }
    &__title {
      width: 100%;
      display: block;
      font-family: 'Albertino';
    }
  }
}
