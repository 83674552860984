.app {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  & > * {
    width: 100%;
    &:first-child {
      flex: 0 0 auto;
    }
    &:nth-child(2) {
      flex: 1;
      overflow: hidden;
    }
    &:last-child {
      flex: 0 0 10%;
    }
  }

}
