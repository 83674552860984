@import 'src/variables';
.about {
  margin: 10vh 0vw;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
  display: flex;
  font-size: 3vh;
  color: #fff212;
  &__text{
    width: 80%;
  }
}
