@import '../../variables';
.menu {
  display: flex;
  padding: 2vh 0 1vw 2vh;
  flex-direction: row;
  gap: 2vw;
  overflow: hidden;
  flex: 1;
  box-sizing: border-box;
  &__category {
    display: flex;
    flex-direction: column;
    row-gap: 0.5vh;
    justify-content: center;
    font-family: Albertino;
    &__item {
      margin: 1vw 0;
      font-size: 2vh;
      color: #8ec74a;
      pointer-events: bounding-box;
      cursor: pointer;
      &:focus,
      &:hover {
        color: $yellow;
      }
      &_isSelected {
        color: $pink;
        text-shadow: -1px -1px 0px $violet, 3px 3px 0px $violet;
      }
    }
  }
}
